import * as React from 'react'
import BlogPostOld from "../../components/blog-post-old";
import image from "../../images/blog19.png"
import image2 from "../../images/blog19-2.png"

export default function InsulinResistanceAndDiabetesAreYouAtRisk(){
    return(
        <BlogPostOld
            src={image}
            title={"Insulin Resistance and Diabetes: Are you at Risk?"}
            summary={"I wanted to touch on this topic because I read an article from an incredible Orthopedic Surgeon Dr. Howard Luks, MD that blew my mind and really challenged my viewpoint on how we screen for those at risk for Diabetes."}
            date={"August 8, 2022"}
            author={"Dr. Donald Mull, DC"}
            InStyle={"max-h-full"}
        >
            <>
                <p>
    <span style={{ color: "#034366" }}>
      Are you at risk for diabetes? You may be surprised by the answer after
      reading this!
    </span>
                </p>
                <p>
    <span style={{ color: "#034366" }}>
      I wanted to touch on this topic because I read an article from an
      incredible Orthopedic Surgeon Dr. Howard Luks, MD that blew my mind and
      really challenged my viewpoint on how we screen for those at risk for
      Diabetes. I loved this article so much that I want to summarize my
      takeaways with the hopes that you are encouraged to read it in its
      entirety.&nbsp;
    </span>
                </p>
                <p>
    <span style={{ color: "#034366" }}>
      <a href={"https://www.howardluksmd.com/carbs-triglycerides-relationship-predict-the-presence-of-insulin-resistance/"}><img src={image2} alt={"article link"}/></a>
    </span>
                </p>
                <p>
                    <span style={{ color: "#034366" }}>My Takeaways:</span>
                </p>
                <ol>
                    <li>
      <span style={{ color: "#034366" }}>
        1. 50% of Americans have insulin resistance, even if they are thin and
        appear to be healthy! (Insulin resistance is a precursor to pre-diabetes
        which is seen in blood sugar screens)
      </span>
                    </li>
                    <li>
      <span style={{ color: "#034366" }}>
        2. Insulin resistance will accelerate atherosclerosis, thus worsening the
        disease course of heart disease, hypertension, dementia, and
        stroke.&nbsp;
      </span>
                    </li>
                    <li>
      <span style={{ color: "#034366" }}>
        3. Too much insulin can result in high blood pressure (hypertension),
        obesity, abnormal lipids, and glucose intolerance.
      </span>
                    </li>
                    <li>
      <span style={{ color: "#034366" }}>
        4. Your pancreas is in charge of making insulin (via beta cells) when you
        eat so glucose can get into cells to make energy - the pancreas can get
        tired and worn down after years of producing too much insulin.
      </span>
                    </li>
                    <li>
      <span style={{ color: "#034366" }}>
        5. Insulin resistance is REVERSIBLE as the pancreas function is still
        salvageable at this stage (much less likely once you have been diagnosed
        with Type 2 Diabetes!!)
      </span>
                    </li>
                    <li>
      <span style={{ color: "#034366" }}>
        6. Once the beta cells of the pancreas stop working (diabetes type 2) they
        may never come back again!! This is why diabetics often need insulin
        injections to keep blood sugar under control.
      </span>
                    </li>
                    <li>
      <span style={{ color: "#034366" }}>
        7. When functioning properly insulin should cause muscles to store carbs
        you eat as glycogen in the muscle - when not functioning properly the
        liver takes most of these carbs increasing VLDL and decreasing HDL
        levels.
      </span>
                    </li>
                    <li>
      <span style={{ color: "#034366" }}>
        8. Insulin Resistance leads to shunting the glucose (carbs) away from
        muscle storage and into your liver (even as early as 20
        year-olds!!).&nbsp;
      </span>
                    </li>
                </ol>
                <p>
    <span style={{ color: "#034366" }}>
      <strong>
        <em>How can you screen for insulin resistance?</em>
      </strong>
    </span>
                </p>
                <ol>
                    <li>
      <span style={{ color: "#034366" }}>
        1. Usual blood tests might not reveal the presence of insulin resistance
        for a decade or more
      </span>
                    </li>
                    <li>
      <span style={{ color: "#034366" }}>
        2. The liver takes on the glucose that the muscle doesn’t so blood sugar
        levels stay in check (hence why screening blood sugar levels won’t
        screen for insulin resistance).
      </span>
                    </li>
                    <li>
      <span style={{ color: "#034366" }}>
        <strong>
          <em>
            3. The screens for insulin resistance are Oral Glucose Tolerance Test
            and Triglyceride to HDL Ratio (below)
          </em>
        </strong>
      </span>
                    </li>
                    <ol className={"pl-8"}>
                        <li>
                            <span style={{ color: "#034366" }}>a. Triglyceride to HDL Ratio:</span>
                        </li>
                        <ol className={"pl-8"}>
                            <li>
                                <span style={{ color: "#034366" }}>i. Take your last blood test</span>
                            </li>
                            <li>
          <span style={{ color: "#034366" }}>
            ii. Look at your triglyceride and HDL levels
          </span>
                            </li>
                            <li>
          <span style={{ color: "#034366" }}>
            iii. Divide your Trigs by your HDL… What is the number you get?
          </span>
                            </li>
                            <li>
                                <span style={{ color: "#034366" }}>iv. It should be less than 3.5</span>
                            </li>
                            <li>
          <span style={{ color: "#034366" }}>
           v. **There are caveats for certain populations to this ratio -{" "}
          </span>
                                <a
                                    href="https://www.howardluksmd.com/carbs-triglycerides-relationship-predict-the-presence-of-insulin-resistance/"
                                    target="_blank"
                                    rel="noopener"
                                >
            <span style={{ color: "#034366" }}>
              read the full article for more
            </span>
                                </a>
                                <span style={{ color: "#034366" }}>**</span>
                            </li>
                        </ol>
                    </ol>
                    <li>
      <span style={{ color: "#034366" }}>
        4. If your fasting triglyceride levels are over 100, you should consider
        having an Oral Glucose Tolerance Test.
      </span>
                    </li>
                    <li>
      <span style={{ color: "#034366" }}>
        5. If your number is above 3.5 then you are at high risk for developing
        Type 2 Diabetes and the chronic diseases associated with it (heart
        disease, dementia, strokes, fatty liver, etc.)
      </span>
                    </li>
                </ol>
                <p>
    <span style={{ color: "#034366" }}>
      <strong>How can we prevent Insulin Resistance or Diabetes?&nbsp;</strong>
    </span>
                </p>
                <ol>
                    <li>
      <span style={{ color: "#034366" }}>
        1. Simple carbs (white breads, candy, etc) create a faster spike in glucose
        which makes it harder for the liver and muscle to handle glucose coming
        in.
      </span>
                    </li>
                    <li>
      <span style={{ color: "#034366" }}>
        2. The main issue driving insulin resistance and weight gain, is total
        caloric excess- eating too much, too often.
      </span>
                    </li>
                    <li>
      <span style={{ color: "#034366" }}>
        3. The biggest storage unit for glucose is our muscles, so we NEED to lift,
        push and pull heavy things to improve how we regulate blood glucose.
        (Your muscles act like an endocrine gland, pretty neat!!)
      </span>
                    </li>
                    <li>
      <span style={{ color: "#034366" }}>
        4. Remember the more glucose that is used by your muscles the less your
        liver will turn into triglycerides.&nbsp;
      </span>
                    </li>
                    <li>
      <span style={{ color: "#034366" }}>
        5. The bigger your muscles, the more glucose it will use (less work on the
        pancreas and liver!)
      </span>
                    </li>
                    <li>
      <span style={{ color: "#034366" }}>
        6. Leg muscles are bigger so first concentrate there and ask for help if
        you need it (also love the riff Dr. Luks goes on about this topic -
        again read the whole article!)
      </span>
                    </li>
                    <li>
      <span style={{ color: "#034366" }}>
        7. Aerobic exercise or increasing our net activity (take your dog on a long
        walk or go on a walk with friends at lunch).&nbsp;
      </span>
                    </li>
                    <li>
      <span style={{ color: "#034366" }}>
        8. Get your sleep! Sleep improves how our body manages sugar
      </span>
                    </li>
                </ol>
                <p>
    <span style={{ color: "#034366" }}>
      Knowing if you are living with insulin resistance is crucial! After
      reading Dr. Luks phenomenal article, I have realized that it is overlooked
      and gone untested in many. Why is it so important? Because this is the
      stage that you can act. The power is still in your hands before it is too
      late! You can save decades of your life and live a long healthy life,
      staying as young as you can for as long as you can.&nbsp;
    </span>
                </p>
                <p>
    <span style={{ color: "#034366" }}>
      This is near and dear to me as I have known people close to me suffer at
      the end stages of their lives as a result of PREVENTABLE diseases that we
      mentioned. I highly encourage you all to take this information and do
      something about it. So take your health into your own hands!
    </span>
                </p>
            </>

        </BlogPostOld>

    )
}